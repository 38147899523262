
.M-form-titulo{
  /*margin-bottom: 80px;*/
}
.MuiSelect-select.MuiSelect-select {
  padding-right: 24px;
  margin-top: 0px;
}
.MuiInputLabel-formControl {
  font-size: 16px!important;
  font-weight: 600!important;
  /* color: #333; */
}
.MuiInputLabel-shrink {
  font-size: 18px!important;

}
.MuiInputBase-input{
  font-weight: 600!important;
}
.Row-flex {
  display: flex;
  align-items: center;
  margin: 45px 0;
  position: relative;
  width: 100%;
  justify-content: space-between;
  .MuiFormControl-marginNormal {
    margin-top: 4px;
    margin-bottom: 8px;
  }
  .Btn {
    width: 100%;
  }
  .Titulo-seccion-form{
    position: absolute;
    top: -30px;
  }

  > div {
    width: 48%;
  }

  &.Row_input_btn{
    > div {
      width: 100%!important;

    }
    & .Btn{
      margin-left: 50px;
    }
  }

  &.Columns3{
    > div {
      width: 30%!important;

    }

  }
}

.Row-flex .MuiFormControl-marginNormal {
  margin-top: 0px!important;
  margin-bottom: 0px!important;
}
.Login .Row-flex .MuiFormControl-marginNormal {
  margin-top: 20px!important;
  margin-bottom: 20px!important;
}
.MuiInputLabel-formControl {
  top: -9px!important;
}
.MuiInputLabel-outlined.MuiInputLabel-formControl {
  top: -4px!important;
}


.Elegir-partida-fixed-top {
  position: fixed;
  top: 0;
  left: 0;
  background: #e3e3e3;
  z-index: 2;
  flex-direction: row !important;
  width: 100%;
  height: 89px;
  align-items: center;
  padding: 1px 55px;
  border-bottom: 1px solid black;
  display: flex;
  width: 100%!important;

  & * {
    font-size: 15px!important;
  }
}

@media (max-width: 768px) {
  .Row-flex {
    flex-direction: column;
  }
  .Row-flex.Columns3 > div {
    width: 100% !important;
  }
}

.Login .MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal.MuiFormControl-fullWidth {
  margin-top: 24px!important;
  margin-bottom: 15px!important;
}