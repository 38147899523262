.css-grid-dashboard{
  display:grid;
  grid-template-columns: auto auto;
  grid-gap: 30px;
}
.verde{
  color: #20ab20 !important;
  font-size: 22px!important;
  font-weight: bold;
}

.css-grid-dashboard>div{
  padding: 40px;
}


.css-grid-dashboard p{
  font-size: 18px;

}

.css-grid-dashboard span{
  font-size: 14px;
  color: gray;

}