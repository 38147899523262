.Header {
  .header {
    padding: 0;

    .header-brand-img {
      height: 80px;
    }
  }

  .dropdown-item, .nav-link {
    cursor: pointer;
    color: #606060 !important;

    i {
      color: #606060 !important;

    }
  }

  .nav-link.active {
    color: #467fcf !important;

    i {
      color: #467fcf !important;

    }
  }
}