* {
  font-family: 'Open Sans', sans-serif !important;
}

.Main {
  position: relative;
  min-height: 75vh;
  padding-top: 50px;
}

.MuiButton-contained span {
  color: white !important;
}

table svg {
  cursor: pointer;
}

.Tarjeta-Principal {
  padding: 50px 45px;
}

.w-32 {
  width: 32% !important;
}

button {
  text-transform: initial !important;
}

.MuiDialog-root h2 {
  font-weight: 600 !important;
}

.MuiBreadcrumbs-ol {
  position: absolute;
  top: 24px
}

.Button-tabla {
  background: #292a2d !important;
  font-weight: 600 !important;
  display: block !important;
  margin: 0 auto !important;

}

.page-content {
  min-height: 80vh;
}

.Bg-white {
  background-color: white !important;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.Bg-Transition {
  background: linear-gradient(270deg, #ececec, #ffffff);
  background-size: 400% 400%;

  -webkit-animation: AnimationName 10s ease infinite;
  -moz-animation: AnimationName 10s ease infinite;
  animation: AnimationName 10s ease infinite;
  height: 100vh;
}

.dropdown-item, .nav-link {
  cursor: pointer;
  color: #606060 !important;

}

.leading-none {
  display: flex;
}

.dropdown-item i {
  color: #606060 !important;

}

.jss1 {
  margin-top: 0 !important;
}

.Div-loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  background-color: #3f51b5;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

@keyframes AnimationName {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

.Box-shadow {
  -webkit-box-shadow: 0px 1px 15px 1px rgba(62, 57, 107, 0.07);
  box-shadow: 0px 1px 15px 1px rgba(62, 57, 107, 0.07);
}

.MuiOutlinedInput-input {
  padding: 14.5px 14px !important;
}

@media (max-width: 768px) {
  .Tarjeta-Principal {
    padding: 50px 1px;
  }
}

.Grid {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-column-gap: 30px;
  grid-row-gap: 15px;

  &.col3 {
    grid-template-columns: auto auto auto;

  }
}

.MuiTableCell-root svg {
  margin-right: 10px !important;

}

@media (max-width: 768px) {
  .Modal-crear {
    .Grid {
      grid-template-columns: auto;
    }
  }
}


.Login .MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal.MuiFormControl-fullWidth {
  margin-top: 24px !important;
  margin-bottom: 15px !important;
}

.Btn-texto {
  background: none;
  border: none;
  cursor: pointer;
  color: gray;

  svg {
    margin-left: 8px;
  }
}

table div, table td {
  font-size: 13px !important;
}

.pyy {
  padding-bottom: 3.5rem !important;
  padding-top: 5rem !important;
}

.labelup label {
  top: -35px !important;
}

.container{
  max-width: 95%!important;
}
