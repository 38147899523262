
.CardMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: white;
  min-height: 200px;
  text-align: center;
  flex-direction: column;
  height: 100%;
  -webkit-box-shadow: 0px 1px 15px 1px rgba(62, 57, 107, 0.07)!important;
  box-shadow: 0px 1px 15px 1px rgba(62, 57, 107, 0.07)!important;
  position: relative;
  max-height: 230px;
 border-radius: 7px;
  span {
    text-transform: uppercase;
    font-size: 20px;
    display: block;
    line-height: 24px;
    max-width: 180px;
    color: #333;
    margin-top: 10px;
  font-weight: 600;
  }
  i{
    font-size: 40px;
    display: block;
    color: #333333;
  }
  svg{
    color: #333;
    font-size: 40px;
    margin-bottom: 5px;
  }
}

.OnHoverText{
  color: black;
  background-color: #f7f7f7;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  padding: 0 30px;
  top: 0;
  border-radius: 7px;

  text-align: center;
  max-width: 100%;
  transition: opacity .2s ease-in-out;
  opacity : 0;
  font-size: 17px;
  font-weight: 600;

}



.CardMenu:hover .OnHoverText {
  opacity: 1;
}