.Login {
  footer{
    display: none;
  }
  .card{
    -webkit-box-shadow: 0px 1px 15px 1px rgba(62, 57, 107, 0.07)!important;
    box-shadow: 0px 1px 15px 1px rgba(62, 57, 107, 0.07)!important;
  }

  .mb-6{
    margin-bottom: 0!important;
  }
  .card-title{
    text-align: center!important;
  }
  img{
    /*max-width: 250px!important;*/
    max-width: 150px!important;
    margin: 30px 0;
    height: auto!important;
  }
  .makeStyles-paper-1{
    margin-top: 0!important;
  }


}

