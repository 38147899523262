.Btn-modal-crear{
  position: inherit!important;
  margin-left: auto!important;
  display: block!important;
  text-transform: initial!important;
  font-size: 14px!important;

}
.Div-boton-abrir-modal{
  margin-top: -60px;
  margin-bottom: 60px;
}
.Modal-crear{
  h2{
    font-size: 1.45rem;
    margin-bottom: 10px;
  }
  h4{
    font-size: 1.25rem;

    margin: 70px 0 15px 0;
  }
  h5{
    font-size: 0.9rem;

    font-weight: 400;
    margin: 20px 0 15px 0;

  }
  .MuiDialog-paper{
    max-width: 80%!important;
    padding-left: 15px;
    padding-right: 15px;
    width: 1000px!important;
  }


}


