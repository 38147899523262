.Row-Grid-facturas,.Row-Grid-presupuestos {
  display: grid;
  grid-template-columns:  1fr 4fr 2fr 1fr 1fr;
  grid-gap: 40px;
  margin: 45px 0;
  position: relative;
  width: 100%;

  >div{
    align-items: flex-end;
  }
}

.CuentaBancaria{
  .MuiDialog-paper{
    height: 1000px!important;
  }
}



.Div-Boton-borrar {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  right: 0;
  height: 50px;
  align-items: center;
  height: 100%;
}

.Boton-crear {
  margin-left: auto!important;
  margin-right: auto!important;
  background-color: #0ed30e!important;
  display: flex!important;
  margin-bottom: 70px!important;

}

.Boton-borrar {
  background: red!important;
}

.Boton-borrar, .Boton-crear {
  color: white;
  border: none;
}

.Precio-row {
  width: 23% !important;
  display: flex;
  margin-left: 5% !important;
  margin-right: auto;
}


.MuiFormControl-marginNormal {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
