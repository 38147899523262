
.MuiTableCell-head{
  font-weight: bold!important;
  font-size: 16px!important;
}
.Tabla-centrada {
  td{
    text-align: center;
  }
  th div{
    margin: 0 auto;
  }
}

/*td,tr,th{
  span,p,h6,div{
    font-family: 'Open Sans','Abel', sans-serif!important;

  }
}*/
.MuiTable-root{
  td:last-child, th:last-child{
    /*min-width: 200px!important;*/
    text-align: center;
  }
}
/*SI El DIV QUE CONTIENE LA TABLA TIENE LA CLASE TABLA-HORAS*/
.Div-tabla-horas{
  .MuiTypography-h6{
    position: absolute;
    top: 15px;
  }
  td,th{
    text-align: center;
    min-width: 114px;
    span{
      display: flex;justify-content: center;
    }
  }
  tbody {
    tr:first-child{
      display:none!important;


    }
   /* tr:nth-child(odd) { background: lightblue}
*/
    td:nth-child(2){
      background-color: #dddddf;
      min-width: 80px;
    }
  }
}
.MuiPaper-elevation4{
  box-shadow: none!important;
}
.MuiToolbar-gutters{
  padding-left: 0!important;
  padding-right: 0!important;
}

.MuiTableCell-root{
  padding-left: 0!important;
}
/**/


/*.MuiTableCell-root{
  text-align: center!important;
}
.MUIDataTableHeadCell-sortAction-66 {
  margin: 0 auto;
}*/



/*MOVIL*/
@media (max-width: 768px){
.MuiTableCell-body{
    height: 58px!important;
    font-size: 13px!important;
    width: 43%!important;

}
  .MuiTableBody-root tr{
    border-bottom: solid 4px rgba(0, 0, 0, 0.15)!important;

  }
  .MUIDataTableBodyCell-responsiveStackedSmall-328 {
    width: 57%!important;
  }
  .MUIDataTablePagination-root-329:last-child {
    padding: 0px 10px 0px 10px!important;
  }
}